import { forwardRef, useState } from "react"
import PhoneInput, {
  getCountryCallingCode,
  Props,
} from "react-phone-number-input"
import { Country } from "react-phone-number-input"

import { E164Number } from "libphonenumber-js/core"

import { useTranslate } from "src/i18n/useTranslate"
import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

import "react-phone-number-input/style.css"

type InputComponentProps = {
  label?: string
  required?: boolean
  helperText?: string
  error?: boolean
}

export function PhoneNumberInput(props: Props<InputComponentProps>) {
  const { t, langKeys } = useTranslate()
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    Country | undefined
  >(props.defaultCountry)

  const countryCode = selectedCountryCode ?? "US"

  const countryCallingCode = getCountryCallingCode(countryCode)

  function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
    event.preventDefault()

    const pastedText = event.clipboardData.getData("text")

    if (isNaN(parseInt(pastedText))) {
      return
    }

    const hasIntlPrefix = pastedText.charAt(0) === "+"

    const intlNumber = hasIntlPrefix
      ? pastedText
      : `+${countryCallingCode}${pastedText}`

    return props.onChange(intlNumber as E164Number)
  }

  return (
    <PhoneInput
      {...props}
      // Remove white spaces from the value to avoid console errors from formatted values
      // that are not E164Number compliant because of white spaces like when editing a number
      // using EditField component
      value={props.value && props.value.replace(/\s+/g, "")}
      inputComponent={PhoneNumberTextField}
      countryCallingCodeEditable={props.countryCallingCodeEditable ?? false}
      label={props.label ?? t(langKeys.phone)}
      helperText={
        props.error
          ? (props.helperText ?? t(langKeys.invalid_phone))
          : undefined
      }
      defaultCountry={countryCode}
      international={props.international ?? true}
      required={props.required ?? true}
      error={props.error ?? false}
      onCountryChange={(value) => {
        props.onCountryChange?.(value)
        setSelectedCountryCode(value)
      }}
      onPaste={handlePaste}
    />
  )
}

const _PhoneNumberTextField = (
  props: TMTextFieldProps,
  ref: React.Ref<HTMLInputElement>
) => {
  return <MTextField ref={ref} {...props} />
}

export const PhoneNumberTextField = forwardRef(_PhoneNumberTextField)
