import {
  formatPhoneNumberIntl,
  isSupportedCountry,
  Value,
} from "react-phone-number-input"
import { isValidPhoneNumber } from "react-phone-number-input"

export function validatePhoneNumber(value: string) {
  return isValidPhoneNumber(value)
}

export function formatPhoneNumber(value: string) {
  // casting to Value here; we might want to look deeper into the parsing
  // options -- e.g., parsePhoneNumberFromString -- from
  // react-phone-number-input if we need to resolve phone number formatting
  // issues in the future
  return formatPhoneNumberIntl(value as Value)
}

/* A wrapperfunction that checks for country codes supported by the
 * react-phone-number-input library.
 * This will make it easy to swap out if we ever change libraries or
 * need to check for a different set of country codes.
 * @param code - The country code to check
 */

export function isValidCountry(code: string = "") {
  return isSupportedCountry(code)
}
